@import 'utils/variables';

.contact-us {
    background-color: var(--sys-center-channel-bg);
    color: var(--sys-button-bg);
    border: 1px solid var(--sys-button-bg);
    padding: 12px 20px;
    border-radius: 4px;
    display: block;
    margin-top: 15px;

    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
    }

    margin-left: 10px
}
