@charset 'UTF-8';

.status-wrapper {
    display: inline-block;
    height: 32px;
    position: relative;
    align-self: center;

    .sidebar--left & {
        height: 36px;
        width: 36px;

        .status {
            bottom: -5px;
            height: 19px;
            right: -6px;
            width: 19px;

            &.status-edit {
                .fa {
                    top: 4px;
                }
            }

            .fa {
                position: relative;
                top: 2px;
            }

            .icon__container {
                &:after {
                    border-radius: 20px;
                    content: '';
                    height: 10px;
                    left: 4px;
                    position: absolute;
                    top: 4px;
                    width: 10px;
                }
            }

            svg {
                height: 13px;
                left: 0;
                max-height: initial;
                position: relative;
                top: 0;
                width: 13px;
                z-index: 1;
            }
        }
    }

    .status {
        border-radius: 100px;
        bottom: -4px;
        height: 15px;
        line-height: 0;
        margin: 0;
        position: absolute;
        right: -3px;
        top: auto;
        width: 15px;

        .modal & {
            bottom: -6px;
        }

        &.status-edit {
            text-align: center;

            i {
                @include opacity(1);
                font-size: 10px;
                position: relative;
                top: 4px;
            }
        }

        svg {
            max-height: 11px;
            position: relative;
            top: 2px;
        }
    }
}

.status-selector:hover>.status {
    opacity: 0;
    visibility: hidden;

    body.enable-animations & {
        @include transition-property(opacity, visibility);
        @include transition-duration($transition-quick);
        @include transition-delay(0s);
        @include transition-timing-function(ease-in, step-end);
    }
}

.status-selector>.status {
    opacity: 1;
    visibility: visible;

    body.enable-animations & {
        @include transition-property(opacity, visibility);
        @include transition-duration($transition-quick);
        @include transition-delay(0s);
        @include transition-timing-function(ease-out, step-start);
    }
}

.status-selector>.status-edit {
    opacity: 0;
    visibility: hidden;

    body.enable-animations & {
        @include transition-property(opacity, visibility);
        @include transition-duration($transition-quick);
        @include transition-delay(0s);
        @include transition-timing-function(ease-in, step-end);
    }
}

.status-selector:hover>.status-edit {
    opacity: 1;
    visibility: visible;

    body.enable-animations & {
        @include transition-property(opacity, visibility);
        @include transition-duration($transition-quick);
        @include transition-delay(0s);
        @include transition-timing-function(ease-out, step-start);
    }
}

.status {
    display: inline-block;
    margin: 0 7px 0 1px;
    position: relative;
    top: 2px;
    width: 15px;

    &.status--group {
        border-radius: 2px;
        font-size: 10px;
        font-weight: 600;
        height: 15px;
        line-height: 14px;
        margin: 0 9px 0 -1px;
        text-align: center;
        width: 15px;
        top: 0;

        .mentions__name & {
            background-color: rgba(var(--center-channel-color-rgb), 0.32);
            flex: 0 0 17px;
            height: 18px;
            left: 0;
            line-height: 18px;
            margin-left: 0;
            text-align: center;
            width: 17px;
        }
    }

    .dnd--icon {
        fill: var(--dnd-indicator);
    }

    .online--icon {
        fill: var(--online-indicator);
    }

    .away--icon {
        fill: var(--away-indicator);
    }

    svg {
        max-height: 14px;
        backface-visibility: hidden;
    }

    i,
    path,
    ellipse {
        @include opacity(.5);

        &.online--icon,
        &.away--icon,
        &.dnd--icon {
            @include opacity(1);
        }
    }

    .fa-lock {
        margin-left: 1px;
    }

    .fa-globe {
        position: relative;
        top: -1px;
    }
}

.status-dropdown-menu {
    .online--icon > svg {
        fill: var(--online-indicator);
    }
    .away--icon > svg {
        fill: var(--away-indicator);
    }
    .dnd--icon > svg {
        fill: var(--dnd-indicator);
    }
    .offline--icon {
        fill: rgba(var(--center-channel-color-rgb), 0.56);
    }
}

.app__body{
    .status{
        &.status--online{
            color: var(--online-indicator);
        }
        .online--icon{
            fill: var(--online-indicator);
        }

        &.status--away {
            color: var(--away-indicator);
        }
        .away--icon {
            fill: var(--away-indicator);
        }
    }
}
