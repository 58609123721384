.AdminSidebarHeader {
    background: #333;

    > div {
        &:first-child {
            display: flex;
            position: relative;
            padding: 10px;
            cursor: pointer;
            align-items: center;
        }

        > img {
            border-radius: 36px;
            height: 36px;
            width: 36px;
        }

        .header__info {
            @include clearfix;
            @include flex-grow(1);
            color: $white;
            padding: 3px 15px 0 8px;
            position: relative;
            width: 100%;
            .team__name {
                font-size: 14px;
                font-weight: 600;
                max-width: 100%;
                overflow: hidden;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .user__name {
                @include opacity(.8);
                font-weight: 400;
            }
        }

        .menu-icon {
            @include opacity(.8);
            fill: $white;
            margin-right: 10px;
        }

        &:hover {
            .user__name,
            .menu-icon {
                @include opacity(1);
            }
            > div > img {
                @include opacity(.8);
            }
        }
    }

    .Menu {
        margin-left: 16px;
    }

}
