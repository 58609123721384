@charset 'UTF-8';

.nav > li > a:focus,
.nav > li > a:hover {
    background: transparent;
}

.nav-tabs {
    margin-bottom: 10px;

    > li {
        margin-right: 5px;

        > a {
            border-bottom-color: transparent !important;
            padding: 7px 15px;

            .small {
                @include opacity(.8);
                margin-left: 4px;
            }
        }
    }
}

#navbar_wrapper {
    input {
        margin: 0 5px 0 2px;
    }

    .navbar-header {
        display: flex;
    }

    .flex-parent--center {
        display: flex;
        justify-content: center;
    }

    .browser--ie & {
        .navbar-default {
            .navbar-brand {
                overflow: visible;
                padding: 1px;

                .heading {
                    max-width: 100px;
                }
            }
        }
    }

    .navbar-default {
        background: $primary-color;
        border: none;
        min-height: 50px;
        position: absolute;

        .navbar-nav {
            > li {
                > a {
                    height: 50px;
                    padding: 0 1.3em;

                    i {
                        line-height: 50px;
                    }
                }
            }
        }

        .navbar-plugin-button {
            position: relative;
            top: 1px;
        }

        .navbar-toggle {
            border-color: transparent;
            border-radius: 0;
            border-width: 0;
            fill: $white;
            float: left;
            height: 50px;
            line-height: 48px;
            margin: 0;
            padding: 0 10px;
            width: 50px;
            z-index: 5;

            &.navbar-right__icon {
                @include border-radius(50px);
                display: flex;
                background: rgba(var(--sidebar-header-text-color-rgb), 0.2);
                align-items: center;
                justify-content: center;
                height: 32px;
                line-height: 0;
                margin: 9px 10px 0 0;
                padding: 0;
                width: 32px;
            }

            &.menu-toggle {
                margin-right: 16px;
            }

            .icon-bar {
                background: var(--sidebar-header-text-color);
                width: 21px;
            }

            .icon__search {
                svg {
                    left: -1px;
                    position: relative;
                    width: 17px;
                }
            }

            &.icon__mute {
                width: 30px;
                padding: 0;
                opacity: .6;
                font-size: 15px;
            }

            .icon--white {
                color: $white;
            }
        }

        .navbar-brand {
            flex: 1 1 auto;
            font-size: 16px;
            height: 50px;
            line-height: 50px;
            overflow: hidden;
            padding: 0;
            display: flex;

            .app__body & {
                color: var(--sidebar-header-text-color)
            }

            .dropdown, .MenuWrapper {
                max-width: calc(100% - 40px);
            }

            .heading {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: auto;
            }

            .header-dropdown__icon {
                margin-left: 6px;
            }

            .dropdown-toggle, .MenuWrapper > a {
                align-items: center;
                color: inherit;
                display: flex;
                overflow: hidden;
                text-decoration: none;
            }

            .description {
                color: inherit;
                display: inline-block;
                margin-right: 1em;
            }
        }
    }

    .sidebar-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
            margin-left: 2px;
            white-space: nowrap;
        }
    }

    .channel-create-btn {
        margin-right: 15px;
    }

    .more-channel-table {
        border: 1px solid #dbdbdc;
        width: 100%;

        td {
            padding: 7px;
        }

        button {
            width: 110px;
        }
    }

    .badge-notify {
        background: $red;
    }
}
