.app__body {
    .DayPicker {
        font-size: inherit;
        width: 100%;
        margin-top: 0.3rem;

        .DayPicker-NavBar {
            position: relative;
        }

        .DayPicker-Caption {
            border-bottom: 1px solid transparent;
            margin: 0 0.5rem .7rem;
            padding: 0 0.5rem .7rem;
            text-align: center;

            >div {
                font-size: inherit;
            }
        }

        .DayPicker-Day {
            min-width: 32px;
            height: 32px;
            border-radius: 100%;
            padding: 0;
            font-weight: 600;
        }

        .DayPicker-Day--today {
            background: rgba(var(--button-bg-rgb), 0.08);
            color: var(--button-bg);
        }

        .DayPicker-wrapper {
            padding: 0;
        }

        .DayPicker-NavButton {
            top: 0;
            right: .5rem;
            margin-top: 2px;
            outline: none;
        }

        .DayPicker-Weekday {
            font-weight: bold;
            color: inherit;
        }

        .DayPicker-Month {
            width: 100%;
            margin: 0;
        }
    }

    .DayPickerInput-Overlay {
        background: rgba(var(--center-channel-bg-rgb), 1);
        border-radius: 4px;
        padding: 20px 24px;
        border: 1px solid rgba(var(--center-channel-color-rgb), 0.08);
        box-shadow: 0 8px 24px 0 alpha-color(black, .12);

        & .DayPicker {
            margin-top: 8px;
        }
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: var(--button-bg);
    }
}
