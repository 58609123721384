@charset 'UTF-8';

::-webkit-scrollbar {
    height: 8px; // for horizontal scrollbars
    width: 8px;  // for vertical scrollbars
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .1);
}

::-webkit-scrollbar-thumb {
    @include border-radius($border-rad * 2);
    background: rgba(0, 0, 0, .2);
}

body {
    &.app__body {
        scrollbar-3dlight-color: #7D7E94;
        scrollbar-arrow-color: #C1C1D1;
        scrollbar-darkshadow-color: #2D2C4D;
        scrollbar-face-color: rgba(var(--center-channel-bg-rgb), .1);
        scrollbar-highlight-color: #7D7E94;
        scrollbar-shadow-color: #2D2C4D;
        scrollbar-track-color: rgba(var(--center-channel-bg-rgb), .1);
    }

    .ps {
        > .ps__scrollbar-y-rail {
            > .ps__scrollbar-y {
                width: 6px !important;
            }
        }

        &:hover {
            > .ps__scrollbar-y-rail {
                &:hover {
                    background: transparent;
                }
            }
        }
    }
}

.scrollbar--horizontal,
.scrollbar--vertical {
    @include border-radius(2px);
    background-color: #999;
}

.scrollbar--view {
    .browser--ie & {
        margin: 0 !important;
        overflow-x: hidden !important;
        -ms-overflow-style: none;

        .scrollbar--vertical {
            visibility: hidden;
        }
    }
}
