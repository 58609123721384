@charset 'UTF-8';

.post {
    .video-div {
        @include border-radius(4px);
        margin-bottom: 8px;
        max-width: 480px;
        overflow: hidden;
        position: relative;
        height: 360px;

        iframe,
        video {
            display: block;
            max-width: 100%;
        }

        .video-thumbnail {
            height: auto;
            max-width: 100%;
        }

        .video-thumbnail__error {
            height: 100%;
            line-height: 2;
            padding: 110px 0;
            text-align: center;
            width: 100%;

            .fa {
                @include opacity(.5);
            }

            div {
                font-size: 1.2em;
            }
        }

        .block {
            background-color: alpha-color($black, .5);
            border-radius: 10px;
            height: 150px;
            left: 50%;
            margin: -75px 0 0 -100px;
            position: absolute;
            top: 50%;
            width: 200px;
        }
    }

    .video-type {
        @include opacity(.8);
        font-size: 15px;
        margin: 0;
        padding: 0;
    }

    .video-title {
        font-size: 15px;
        margin-top: 3px;
    }

    .play-button {
        @include border-radius(14px);
        border: 4px solid alpha-color($white, .4);
        height: 100px;
        position: absolute;
        right: 51px;
        top: 26px;
        width: 100px;

        span {
            border-bottom: 36px solid transparent;
            border-left: 60px solid alpha-color($white, .4);
            border-top: 36px solid transparent;
            height: 0;
            left: 20px;
            position: absolute;
            top: 10px;
            width: 0;
        }
    }

    .img-div {
        @include single-transition(all, .1s, linear);
        -moz-force-broken-image-icon: 1;
        border-radius: 4px;
        margin-bottom: 8px;
        max-height: 350px;
        max-width: 480px;
        position: relative;

        &:hover {
            @include box-shadow(0 2px 5px 0 rgba($black, 0.1), 0 2px 10px 0 rgba($black, 0.1));
        }

        &.placeholder {
            height: 350px;
        }

        .sidebar--right & {
            max-width: 100%;
        }
    }

    .video-loading {
        height: 368px;
    }

    .video-div__placeholder {
        height: 360px;
        display: flex;
        align-items: center;
        background-color: black;
    }
}
