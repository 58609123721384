@charset "UTF-8";

.reacticon {
    display: inline-block;
    fill: inherit;
    opacity: .7;
    position: relative;
    top: 2px;
    vertical-align: middle;
    visibility: hidden;
}

.emoticon {
    border: none !important;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-flex;
    align-items: end;
    justify-content: space-between;
    height: 21px;
    min-height: 21px;
    min-width: 21px;
    vertical-align: middle;
    width: 21px;
    overflow: hidden;

    &--unicode {
        font-size: 21px;

        .os--windows & {
            font-size: 16px;
            line-height: 1.3;
        }
    }

    &:not(.emoticon--unicode) {
        color: transparent;

        &::selection{
            background-color: transparent;
            color: transparent;
        }
    }
}

.emoticon-suggestion {
    @include clearfix;
    cursor: pointer;
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    width: 100%;

    > div {
        &:first-child {
            text-align: center;
            width: 30px;
        }
    }
}

.emoticon-suggestion__image {
    margin-top: -2px;
    max-height: 20px;
    max-width: 20px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
}

.emoji-picker {
    @include border-radius($border-rad);
    @include clearfix;
    @include user-select(none);
    border-radius: 4px;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    margin-right: 3px;
    position: absolute;
    width: 350px;
    z-index: 1100;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);

    .emoji-picker__header{
        background: var(--sidebar-header-bg);
    }

    &.emoji-picker--single {
        .emoji-picker__header {
            position: relative;
        }
    }

    .browser--ie & {
        width: 325px;
    }

    .app__content & {
        margin-right: 0;
    }

    &.bottom {
        margin-top: 10px;
    }

    .nav-tabs {
        margin-bottom: 0px;
        flex: 0 0 34px;
        display: flex;
        justify-content: flex-end;

        li {
            flex: 1;
            height: 34px;
            font-size: 12px;
            font-weight: 600;

            &:first-child {
                margin-left: 7px;
            }

            a {
                border: none !important;
                border-bottom: 2px solid transparent !important;
                border-bottom-width: 2px;
                border-bottom-style: solid;
                outline: none;
                height: 40px;
                margin-left: 5px;
                margin-right: 5px;
                padding: 7px 12px;
            }
        }

        li.active {
            a {
                opacity: 1;
                height: 34px;
                margin-left: 5px;
                border-radius: 0;
                opacity: 1;
            }
        }

        svg {
            position: relative;
            top: 2px;
        }
    }

    .tab-content {
        border-top: 1px solid;
        border-color: rgba(61, 60, 64, 0.2);
    }
}

.emoji-picker__categories {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    margin-bottom: 8px;

    .emoji-picker__category {
        color: v(center-channel-color-48);
        width: 28px;
        height: 28px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        text-decoration: none;

        &:hover {
            background-color: rgba(var(--center-channel-color-rgb), 0.04);
            color: var(--center-channel-color-72);
        }

        &:active {
            color: var(--button-bg);
            color: v(center-channel-color);
        }

        &--selected {
            background: rgba(var(--button-bg-rgb), 0.08);
            fill: var(--button-bg);
            color: var(--button-bg);
            border-radius: 4px;
        }

        &.disable {
            pointer-events: none;
        }

        i {
            font-size: 18px;
        }
    }
}

.emoji-picker__header,
.modal-body .emoji-picker .emoji-picker__header {
    display: none; // only visible < 480px width screen
    padding: 13px 10px 13px 15px;
    background: $primary-color;
    border: 1px solid $light-gray;
    color: $white;
    position: absolute;
    top: 0;
    width: 100%;

    .emoji-picker__header-title {
        margin-top: 2px;
        font-size: 17px;
    }

    .emoji-picker__header-close-button {
        width: 30px;
        height: 30px;
        opacity: 1;
        color: $white;
        background: transparent;

        &:hover {
            background: rgba($black, 0.1);
        }
    }
}

.modal-body .emoji-picker .emoji-picker__header {
    .emoji-picker__header-title {
        margin-top: 0px;
    }
}

.emoji-picker__search-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    margin: 12px;
    border-color: transparent !important;

    .skin-tones {
        @include single-transition(all, .35s, ease);
        z-index: 10;
        height: 100%;
        display: flex;
        align-items: center;
        background: var(--center-channel-bg);
        overflow: hidden;

        &--active {
            position: absolute;
            width: 100%;
            justify-content: space-around;

            .skin-tones__close,
            .skin-tones__icons {
                opacity: 1;
                overflow: hidden;
                display: inline-flex;
                justify-content: center;
                position: relative;
                margin: 0 3px;
                .skin-tones__icon {
                    width: 32px;
                }
            }
        }

        .skin-tones__icon {
            opacity: 1;
            overflow: hidden;
            display: inline-flex;
            justify-content: center;
            position: relative;
            height: 32px;
            width: 32px;
            padding: 3px;

            &:hover {
                background-color: rgba(var(--center-channel-color-rgb), 0.08);
                border-radius: 4px;
            }

            & img {
                -moz-transform: matrix(0.35, 0, 0, 0.35, 0, -20); // firefox displaces the transformation, so we need to compensate
            }
        }

        .emoji-picker__item {
            &:last-child {
                margin: 0;
            }
        }
    }

    .skin-tones__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: 32px;
        &.skin-tones__content__single {
            padding-right: 4px;
            padding-left: 10px;
        }
    }

    .emoji-picker__text-container {
        position: relative;
        z-index: 5;
        width: 95%;
        height: 32px;
        width: 284px;
        border: 1px solid var(--center-channel-color-24);
        position: relative;
        z-index: 5;

        &:focus-within {
            border: 2px solid v(button-bg);
            & .emoji-picker__search-icon {
                padding-left: 8px;
                padding-top: 7px;
            }
        }
    }

    .skin-tones__close {
        @include single-transition(opacity, .35s, ease);
        display: flex;
        align-items: center;
    }

    .skin-tones__icons {
        @include single-transition(opacity, .35s, ease);
        opacity: 0;
    }

    .skin-tones__close-text {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        width: 60px;
    }

    .skin-tones__close-icon {
        border: none;
        background: none;
        width: 32px;
        height: 32px;
        margin-right: 12px;
        border-radius: 4px;
        color: rgba(v(center-channel-color-rgb), 0.56);

        &:hover {
            background: rgba(v(center-channel-color-rgb), 0.08);
            color: rgba(v(center-channel-color-rgb), 0.72);
        }

        &:active,
        &--active,
        &--active:hover {
            background: rgba(var(--button-bg-rgb), 0.08);
            fill: v(button-bg);
            color: v(button-bg);
        }
    }

    .color-selector {
        @include single-transition(all, .35s, ease);
        border-radius: 4px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        z-index: 11;

        &:hover {
            background-color: rgba(var(--center-channel-color-rgb), 0.08);
        }

        .emoji-picker__item {
            margin: 0;

            &:hover {
                background: transparent;
            }
        }
    }

    .emoji-picker__search-icon {
        padding-left: 9px;
        padding-top: 8px;
        position: absolute;
        font-size: 14px;
    }

    .emoji-picker__search {
        border-width: 0px;
        border-radius: inherit;
        padding: 4px 0 0px 31px;
        width: 100%;
        height: 26px;
        font-size: 13px;

        &:focus{
            outline: none;
            padding: 2px 0 0px 30px;
        }
    }
}

.emoji-picker__items {
    border-top-style: solid;
    border-top-width: 1px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 18px;
    position: relative;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    height: 270px;
    padding: 4px 0 4px 12px;

    .emoji-picker__container {
        min-height: 100%;
        position: relative;
        min-width: 325px;
        min-height: 100%;
    }

    .emoji-picker__category-header {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 6px;
        margin-top: 3px;
        padding-top: 3px;
        color: var(--center-channel-color-48);
        text-transform: uppercase;
        position: relative;
    }

    .emoji-picker__preview_sprite {
        display: inline-block;
        height: 45px;
        margin: 3px;
        padding: 3px;
        vertical-align: top;
    }

    .emoji-picker-items__container {
        > span {
            border-radius: 6px;
            border: 0;
            cursor: pointer;
            display: inline-block;
            height: 21px;
            margin: 3px;
            overflow: hidden;
            padding: 0;
            text-align: center;
            vertical-align: top;
            width: 21px;
        }
    }

    .emoji-picker__item-wrapper {
        .emoji-picker__item {
            font-size: 18px;
            height: auto;
            margin: 0 auto;
            max-height: 21px;
            max-width: 21px;
            min-height: 0;
            min-width: 0;
            width: auto;
        }
    }
}

.emoji-picker__item:nth-of-type(9n) {
    margin-right: 0px;
}

.emoji-picker__item {
    border-radius: 3px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    width: 36px;

    &.selected,
    &:hover {
        background-color: rgba(var(--center-channel-color-rgb), 0.16);
    }

    &:active {
        background-color: rgba(var(--center-channel-color-rgb), 0.32);
    }

    > div {
        text-align: center;
    }

    img {
        position: relative;

        &.emoji-category--custom {
            @include transform(scale(1));
            height: auto;
            left: auto;
            max-height: 22px;
            max-width: 22px;
            top: auto;
            width: auto;
        }
    }
}

.emoji-tooltip {
    z-index: 99999;
}

.emoji-picker__footer {
    display: flex;
    border-top: solid 1px;
    justify-content: space-between;
    border-color: rgba(61, 60, 64, 0.2);
}

.emoji-picker__custom {
    margin: 12px;
    position: relative;
    text-decoration: none;

    a,
    a:hover {
        background: rgba(var(--button-bg-rgb), 0.08);
        fill: var(--button-bg);
        color: var(--button-bg) !important; // it gets overriden by link color
        border-radius: 4px;
        font-size: 12px;
        font-weight: 600;
        text-decoration: none;
    }

    a:hover {
        background-color: rgba(var(--button-bg-rgb), 0.12);
    }
}

.emoji-picker__preview {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    height: 56px;
    justify-content: flex-start;
    padding: 18px 0 18px 16px;
    position: relative;
    vertical-align: middle;
    max-width: 210px;
    overflow: hidden;

    &.emoji-picker__preview-placeholder {
        padding: 12px;
        span {
            font-weight: bold;
            display: flex;
            align-items: center;
        }
    }

    > div {
        display: table-cell;
        vertical-align: middle;
    }

    .emoji-picker__preview-image-label-box {

        .emoji-picker__preview-name {
            font-size: 14px;
            font-weight: bold;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 120px;
            display: inline-block;
        }

        .emoji-picker__preview-aliases {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .emoji-picker__preview-image-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        max-height: 36px;
        width: 32px;
        text-align: center;
        position: relative;
        margin-right: 12px;

        .sprite-preview {
            border: none;
            display: inline-block;
            height: 32px;
            padding: 0;
            vertical-align: middle;
            width: 32px;
            margin-top: -20px;
        }
    }

    .emoji-picker__preview-image {
        left: 2px;
        max-height: 36px;
        max-width: 36px;
        position: relative;
        top: -8px;
    }
}
