@charset "UTF-8";

@function em($pixels, $context: 14px) {
	@return ($pixels / $context * 1em)
}

@function v($var) {
    @return var(--#{$var});
}

@function alpha-color($color, $amount) {
    @return rgba($color, $amount)
}

%popover-box-shadow {
    @include box-shadow(rgba(black, .175) 1px -3px 12px);
}

%box-shadow-1 {
    @include box-shadow(rgba(black, .1) 0 1px 16px);
}

%font-awesome {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

@mixin unselectable {
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */

    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
}
