@import 'utils/variables';

.annnouncementBar__purchaseNow {
    background: var(--sys-button-bg);
    color: var(--sys-button-color);
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    display: block;
    margin-top: 15px;

    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
    }
}
