@charset 'UTF-8';

@mixin file-icon($path) {
    @include background-size(32px 40px);
    background-image: url($path);
    background-position: center;
    background-repeat: no-repeat;
}

@mixin alpha-property($property, $color, $opacity) {
    #{$property}: rgba($color, $opacity);
}

@mixin font-smoothing($value: antialiased) {
    @if $value == antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@mixin cursor($value) {
    cursor: -webkit-$value;
    cursor: zoom-$value;
}

// From https://gist.github.com/kaelig/7528069
@mixin text-clamp($lines: 2, $line-height: false) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;

    // Fallback for non-Webkit browsers
    // (won't show `…` at the end of the block)
    @if $line-height {
        max-height: $line-height * $lines * 1px;
    }
}

@mixin button-style--none {
    background: transparent;
    border: none;
    padding: 0;

    &:focus {
        outline: 0;
        text-decoration: none;
    }

    &.btn {
        padding: 6px 12px;
    }

    &.btn--block {
        text-align: left;
        width: 100%;
    }

    &:hover,
    &:active {
        text-decoration: none;
    }
}
