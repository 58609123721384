@charset 'UTF-8';

.alert {
    @include border-radius($border-rad);
    padding: 8px 12px;
}

.alert-grey {
    background: #f2f2f2;
    color: #999999;
}

.alert--confirm {
    display: inline-block;
    float: left;
    margin: 1px 0 0 10px;
    padding: 4px 10px;
}

.alert .btn-close {
    font-size: 21px;
    line-height: 20px;
    float: right;

    &, &:link, &:visited, &:hover, &:focus, &:active {
        color: inherit;
        text-decoration: none;
    }
}
