#ldap_groups {
    overflow: visible;
}

.groups-list {
    @include single-transition(all, .4s, ease-in-out, 0s);
    padding: 2rem 2rem 2rem 2rem;

    &.groups-list-less-padding {
        padding: 0;
    }

    .groups-list--global-actions {
        position: relative;
        display: flex;
        background: $white;
        padding: 8px 20px;

        .group-list-search {
            display: flex;
            flex-grow: 1;
            stroke: $dark-gray;
            position: relative;
            border: 1px solid rgba(0, 0, 0, .1);
            border-radius: 15px;
            height: 30px;
            max-width: 280px;

            input {
                border: 0px;
                margin-left: 25px;
                margin-right: 15px;
                font-size: .95em;
                width: 100%;
            }

            input:focus {
                outline: none;
            }

            margin-top: 2px;

            .group-filter-action {
                padding: 8px 10px 0px 0px;

                &.hidden {
                    visibility: hidden;
                }
            }
        }

        .group-list-link-unlink {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
        }

        .group-search-filters {
            position: absolute;
            width: 303px;
            background-color: white;
            top: 40px;
            border: 1px solid rgba(0, 0, 0, .1);
            padding: 20px;

            .search-groups-btn {
                float: right;
                bottom: 20px;
                right: 20px;
            }

            .cancel-filters {
                float: right;
            }

            .filter-row {
                height: 30px;
                vertical-align: middle;
            }

            .filter-check {
                border-radius: 3px;
                border: 2px solid $dark-gray;
                height: 18px;
                width: 18px;
                display: inline-block;
                margin-right: 7px;
                vertical-align: middle;

                &.checked {
                    border: 0;

                    svg {
                        background: $white;
                        fill: $primary-color;
                    }
                }
            }
        }
    }

    .groups-list--header {
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        font-size: 1.1em;
        font-weight: bold;
        padding: .5em 0;

        .group-name {
            margin-left: 16px;
        }

        .group-description {
            color: $black;
            display: block;
            width: 150px;
            opacity: 1;

            &.adjusted {
                width: 100px;
            }
        }

        .group-actions {
            width: 130px;
        }
    }

    .groups-list--body {
        background: $bg--gray;
        cursor: pointer;

        .groups-list-loading {
            text-align: center;
            padding: 40px;
        }

        .groups-list-empty {
            text-align: center;
            margin-top: 1rem;
            font-size: 1.6rem;
            background: alpha-color($white, .5);
            color: $gray;

            span {
                display: inline-block;
                width: 100%;
                height: 100%;
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
    }

    .groups-list--footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background: $white;
        padding: 5px;
        height: auto;

        .btn-link {
            color: $dark-gray;

            &.disabled {
                opacity: .5;
            }
        }

        .counter {
            color: $dark-gray;
            font-size: 1.1em;
            margin-bottom: 2px;
        }

        border-top: solid 1px rgba(0, 0, 0, 0.1);
    }

    .group-check {
        align-items: center;
        border-radius: 3px;
        border: 2px solid $dark-gray;
        display: flex;
        justify-content: space-around;
        margin: 5px 5px 4px 14px;
        min-height: 18px;
        min-width: 18px;

        &.checked {
            border: 0;

            svg {
                background: $white;
                fill: $primary-color;
            }
        }
    }

    .group-name {
        display: flex;
        flex-grow: 1;
        flex: 1.8;
        align-items: center;
    }

    .group-content {
        padding-left: 20px;
        display: flex;
        flex: 1;
    }

    .group-description {
        align-items: center;
        display: flex;
        height: 100%;
        overflow: hidden;
        opacity: .65;
        width: 150px;

        &.adjusted {
            width: 120px;
        }

        &.roles {
            overflow: visible;
        }

        i {
            margin-right: 5px;
        }

        >a {
            color: $black;
            text-decoration: none;
            padding: 3px 5px;

            &:hover {
                background-color: $light-gray;
                border-radius: 3px;
            }

            &.warning {
                color: $red;
            }
        }
    }

    .group-description-link {
        >a {
            &:hover {
                background-color: transparent;
                text-decoration: underline;
            }
        }
    }

    .group-actions {
        width: 130px;
        text-align: right;
        padding-right: 20px;
    }

    .group {
        &.checked {
            background-color: lighten($primary-color, 40%);

            .group-description {
                opacity: 1;
            }
        }

        &:nth-child(even) {
            background: alpha-color($white, .5)
        }

        &.checked:nth-child(even) {
            background-color: lighten($primary-color, 40%);
        }

        .group-row {
            .group-name {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    .group-row {
        align-items: center;
        border: 2px solid $transparent;
        display: flex;
        flex-direction: row;
        height: 34px;

        &:hover {
            background-color: lighten($primary-color, 45%);
            border-left: 2px solid $primary-color;

            .group-description {
                opacity: 1;
            }
        }

        &.selected {
            animation-duration: 3s;
            animation-name: rowhighlight;
        }

        &.group-row-large {
            height: 80px;
        }
    }

    .channel-icon {
        opacity: 0.8;
    }
}

#groupDisplayName {
    &.group-profile {
        padding-bottom: 0px;
    }
}

#groupMention {
    .group-profile {
        padding-top: 0px;
    }
    .group-at-mention-input {
        padding-left: 26px;
    }
}

.group-profile {
    padding: 20px;
}

.group-users {
    .group-users--header {
        background: #333;
        color: $white;
        padding: 10px 20px;

        a {
            color: $white;
            text-decoration: underline;
        }
    }

    .group-users--body {
        margin: 15px 15px 0 15px;
        position: relative;

        .group-users-loading {
            display: none;
            text-align: center;
            width: 100%;
            height: 100%;
            position: absolute;
            background: $light-gray;

            &.active {
                display: flex;
                align-items: center;
                justify-content: center;

                .fa-spinner {
                    max-width: 10%;
                }
            }
        }

        .group-users-empty {
            text-align: center;
            padding: 20px;
            font-size: 1.5em;
            background: alpha-color($white, .5);
            color: $gray;
        }

        .group-users-row {
            &:nth-child(even) {
                background: alpha-color($white, .5)
            }

            display: flex;
            align-items: center;
            padding: 10px 15px;

            .profile-picture {
                width: 32px;
                height: 32px;
                margin: 5px 10px;
                border-radius: 100%;
            }

            .user-data {
                margin-left: 10px;

                .name-row {
                    display: flex;
                }

                .display-name,
                .email {
                    color: $gray;
                }
            }

            .email-label {
                margin-right: 5px;
            }
        }
    }

    .group-users--footer {
        margin: 0 15px 15px 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background: $white;
        padding: 5px;
        height: auto;

        &.empty {
            border-top: 0;
            padding: 0;
            height: 0;
        }

        .btn-link {
            color: $dark-gray;

            &.disabled {
                opacity: .5;
            }
        }

        .counter {
            color: $dark-gray;
            font-size: 1.1em;
            margin-bottom: 2px;
        }
    }

}

.channel-moderation {
    .channel-moderation--body {
        padding: 20px;

        .channel-moderation--table {
            width: 100%;

            th {
                &:first-child {
                    width: 100%;
                }

                border-bottom: solid 1px alpha-color(#CCCCCC, .75);
            }

            tr {
                &:not(:last-child){
                    border-bottom: solid 1px alpha-color(#CCCCCC, .75);
                }

                &:last-child{
                    td {
                        padding-bottom: 0px;
                    }
                }
            }

            td {
                padding: 20px 0px;

                &:first-child {
                    padding-left: 0px;
                }

                &:not(:first-child) {
                    text-align: center;
                    vertical-align: middle;
                }
            }

            th {
                padding: 6px 20px;

                &:first-child {
                    padding-left: 0px;
                }
            }

            .checkbox {
                border-radius: 3px;
                border: 2px solid $dark-gray;
                height: 18px;
                width: 18px;
                padding: 0;
                background: none;
                display: inline-block;

                > * {
                    pointer-events: none;
                }

                &.disabled {
                    border-color: $light-gray;
                }

                &.checked {
                    border: 0;

                    svg {
                        fill: #145DBF;
                    }
                }
            }
        }
    }
}

.group-teams-and-channels {
    .group-teams-and-channels-loading {
        text-align: center;
        padding: 40px;
    }

    .group-teams-and-channels-empty {
        text-align: center;
        padding: 20px;
        font-size: 1.5em;
        background: alpha-color($white, .5);
        color: $gray;
    }

    .group-teams-and-channels--header {
        font-size: 1.1em;
        font-weight: bold;
    }

    .group-teams-and-channels--body {
        padding: 20px;

        .group-teams-and-channels-row.has-clidren {
            .type {
                margin-left: 20px;
            }
        }
        .group-teams-and-channels-row {
            &:nth-child(even) {
                background: alpha-color($white, .5)
            }

            .team-icon,
            .channel-icon {
                width: 16px;
                height: 16px;
                margin: 0 7px 0 4px;
                color: inherit;
                opacity: .7;
            }

            .arrow-icon {
                .fa {
                    cursor: pointer;
                    padding: 0 4px;
                }
            }

            .channel-icon {
                margin: 1px 2px 0 20px;
            }

            .team-icon-private {
                font-size: .35em;
            }

            .actions {
                button {
                    padding: 5px;
                }
            }

            .type {
                color: #454448;
                opacity: 50%;
            }

            .name-no-arrow{
                margin-left: 16px;
            }

            .name-content {
                flex: 1;
                display: flex;

                .name {
                    flex: 1;
                }

                .arrow-icon {
                    margin-left: 10px;

                    .fa-caret-right {
                        padding: 5px;
                        cursor: pointer;
                    }

                    .open {
                        transform: rotate(90deg);
                        transform-origin: 55% 50%;
                        transition: transform 300ms;
                    }
                }
            }


            .type{
                flex: 1;
                margin-left: -29px;
            }

            .remove {
                height: 30px;
            }
        }

        .teams-list {
            .teams-list--header {
                font-weight: bold;
                display: flex;
                font-size: 1.1em;
                border-bottom: 1px solid $dark-gray;
                justify-content: space-between;
            }
        }
    }
}

.group-settings {
    .group-settings--body {
        padding: 0px 20px 20px 20px;
    }
}

.group-profile-add-menu {
    position: relative;
    margin-left: 10px;
    margin-top: -36px;

    .fa-caret-down {
        margin-left: 5px;
    }

    .add-team-or-channel-menu {
        position: absolute;
        top: 33px;
        border: 1px solid $gray;
        background: $white;
        border-radius: 2px;
        width: 100%;
        padding: 5px 8px;

        a {
            display: block;
            color: $dark-gray;
            padding: 7px;
        }
    }
}


.group-user-profile-picture {
    width: 32px;
    height: 32px;
    margin: 5px 10px;
    border-radius: 100%;
}

.group-users-to-remove-modal-description {
    padding: 16px;
    background-color: #F5F5F5;
}

.group-description.group-user-removal-column {
    height: 40px;
}

#team_groups .group-row .group-description,
#channel_groups .group-row .group-description {
    width: 170px;
}

.section-separator {
    height: 4rem;
    margin: 0;
    position: relative;
    text-align: center;
    z-index: 0;

    hr {
        border-color: #333333;
        opacity: .12;
        margin: 0;
        position: relative;
        top: 2rem;
        z-index: 5;
    }
}

.email-group-row {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
}

.group-users--header-padded {
    padding-left: 24px;
}

.icon-over-input {
    padding-left: 12px;
    padding-top: 9px;
    position: absolute;
    opacity: .8;
}

.AdminChannelDetails_archiveContainer {
    border-top: 1px solid rgba(0, 0, 0, .1);
    position: relative;
    width: 920px;
    left: -22px;
    display: block;
    padding-top: 20px;
    padding-left: 20px;
    margin-top: 20px;
}

.AdminChannelDetails_archiveContainer .ArchiveButton {
    padding: 9px 16px;
}

.AdminChannelDetails_archiveContainer .channel-icon__archive,
.AdminChannelDetails_archiveContainer .channel-icon__unarchive {
    width: 16px;
    height: 16px;
}

$archived-color: #166DE0;
$unarchived-color: #F74343;

.ArchiveButton___archived {
    border-color: rgba($archived-color, 1);
    color: rgba($archived-color, 1);
    svg {
        fill: rgba($archived-color, 1);
    }
}

.ArchiveButton___unarchived {
    border-color: rgba($unarchived-color, 1);
    color: rgba($unarchived-color, 1);
    svg {
        fill: rgba($unarchived-color, 1);
    }
}

.ArchiveButton {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    background-color: transparent;
}

.ArchiveButton___unarchived:hover {
    background-color: rgba($unarchived-color, .06);
    color: rgba($unarchived-color, 1);
}
.ArchiveButton___archived:hover {
    background-color: rgba($archived-color, .06);
    color: rgba($archived-color, 1);
}

.ArchiveButton:focus,
.ArchiveButton:focus:active,
.ArchiveButton:active {
    outline: none;
}

.ArchiveButton___archived:focus {
    color: rgba($archived-color, 1);
}

.ArchiveButton___unarchived:focus {
    color: rgba($unarchived-color, 1);
}

.ArchiveButton___archived:focus:active {
    background-color: rgba($archived-color, .12);
}

.ArchiveButton___unarchived:focus:active {
    background-color: rgba($unarchived-color, .12);
}

.ArchiveButton:active {
    box-shadow: none;
}