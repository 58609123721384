@charset 'UTF-8';

body {
    &.announcement-bar--fixed {
        .signup-header {
            top: 24px;
        }
    }
}

.signup-header {
    background: $bg--gray;
    line-height: 33px;
    padding: 0 1em .2em;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;

    .fa {
        margin-right: 4px;
    }
}

.signup-team__container {
    margin: 0 auto;
    max-width: 400px;
    padding: 100px 0 50px;
    position: relative;

    .medium-center {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    .terms-of-service__markdown {
        background-color: #fff;
        overflow-y: auto;
        padding-top: 3em;
        padding-bottom: 3em;
        min-width: 100vw;
    }

    &.branded {
        @include display-flex;
        @include flex-direction(row);
        max-width: 900px;

        .signup__markdown {
            @include flex(1.3 0 0);
            padding-right: 80px;

            ul + p,
            ol + p {
                margin-top: .6em;
            }

            p + ul,
            p + ol {
                margin-top: .6em;
            }

            img {
                max-width: 450px;
            }

            p {
                color: lighten($black, 50%);
                line-height: 1.6em;
                margin: 0;
                white-space: pre-wrap;
                width: 100%;
                word-break: break-word;
            }
        }

        .signup__content {
            @include flex(1 0 0);
        }

    }

    &.padding--less {
        padding-top: 70px;
    }

    &.terms-of-service__container {
        padding-top: 33px;
        max-width: 100%;
    }

    .terms-of-service__footer {
        padding-top: .5em;
        padding-bottom: .5em;

        .terms-of-service__button-group {
            padding-top: 1em;
            padding-bottom: 1em;
        }

        .terms-of-service__server-error {
            padding-bottom: 1em;
        }
    }

    &.max-width--full {
        max-width: 100%;
    }

    .form-control:focus {
        @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6));
    }

    .terms-of-service-error__height--fill {
        min-height: calc(100vh - 107px - 4em);
        height: calc(100vh - 107px - 4em);
    }

    .terms-of-service__height--fill {
        min-height: calc(100vh - 69px - 3em);
        height: calc(100vh - 69px - 3em);
    }

    .medium-center {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        line-height: 1.3;
    }

    h1 {
        font-weight: 600;
    }

    h2 {
        font-size: em(30px);
        font-weight: 600;
        letter-spacing: -.5px;
        margin-bottom: .8em;
    }

    h3 {
        font-size: 1.5em;
        font-weight: 600;
        margin: 0 0 1.3em;
    }

    h4 {
        font-size: em(20px);
        font-weight: 600;
        margin-bottom: 1em;
    }

    h5 {
        font-size: em(16px);
    }

    hr {
        margin: 2em 0;
    }

    p {
        color: #555555;
        line-height: 1.5;
        margin-bottom: 1em;
    }

    .input-group {
        &.input-group--limit {
            table-layout: fixed;
            width: 100%;

            .tooltip-inner {
                word-wrap: break-word;
            }

            .form-control {
                display: table-cell;
                text-align: left;
                width: 100%;
            }

            .input-group-addon {
                display: table-cell;
                overflow: hidden;
                text-align: left;
                text-overflow: ellipsis;
                width: 50%;
            }
        }
    }

    .inner__content {
        margin: 30px 0 20px;
    }

    .block--gray {
        @include border-radius(3px);
        background: #f2f2f2;
        display: inline-block;
        font-weight: 600;
        padding: .85em 1.2em;
    }

    form {
        margin-bottom: .8em;
    }

    .signup-team-confirm__container {
        padding: 100px 0;
    }

    .signup-team-logo {
        display: none;
        margin: 0 0 2em;
        width: 210px;
    }

    .signup-team-login {
        font-weight: 600;
        padding-bottom: 10px;
    }

    .signup-team__name {
        font-size: 2.2em;
        font-weight: 600;
        margin: .5em 0 0;
    }

    .signup-team__subdomain {
        font-size: 1.5em;
        font-weight: 300;
        margin: .2em 0 1.2em;
        text-transform: uppercase;
    }

    .form-control {
        height: em(38px);
    }

    .or__container {
        background: #dddddd;
        height: 1px;
        margin: 2em 0;
        margin: 2.5em 0 2.5em -1rem;
        text-align: left;

        span {
            background: #ffffff;
            display: inline-block;
            font-size: 1.14286em;
            font-weight: 600;
            line-height: 20px;
            position: relative;
            text-align: center;
            top: -10px;
            width: 40px;
        }
    }

    ul {
        margin-bottom: 0;
        padding-left: 18px;
    }

    .btn {
        font-size: 1em;
        font-weight: 600;
        margin-right: 5px;
        padding: em(7px) em(15px);

        .fa {
            margin: 0;
            top: 1px;
            font-size: 17px;

            &:last-child {
                margin-left: 8px;
            }

            &:first-child {
                margin-right: 8px;
            }

            &.fa--margin-top {
                position: relative;
                top: 1px;
            }
        }

        .icon {
            @include background-size(100% 100%);
            display: inline-block;
            height: 18px;
            margin-right: 12px;
            position: relative;
            text-align: center;
            width: 18px;
        }

        &.btn-custom-login {
            @include border-radius(2px);
            color: $white;
            display: block;
            height: 40px;
            margin: 1em 0;
            min-width: 200px;
            padding: 0 24px;
            text-align: left;
            display: flex;
            align-items: center;
            width: 200px;

            > span {
                position: relative;
                display: flex;
                align-items: center;

                > span {
                    line-height: normal;
                }
            }

            &.gitlab {
                background: #554488;

                &:hover {
                    background: darken(#554488, 10%);
                }

                .icon {
                    background-image: url('../images/gitlabLogo.png');
                }
            }

            &.google {
                background: #dd4b39;

                &:hover {
                    background: darken(#dd4b39, 10%);
                }

                .icon {
                    background-image: url('../images/googleLogo.png');
                }
            }

            &.office365 {
                background: #0079d6;

                &:hover {
                    background: darken(#0079d6, 10%);
                }

                .icon {
                    background-image: url('../images/office365Logo.png');
                }
            }

            &.openid {
                white-space: normal;
                background: #0079d6;

                &:hover {
                    background: darken(#0079d6, 10%);
                }
                > span {    
                    > span {
                        line-height: normal;
                        width: 150px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }    
            }

            &.ldap {
                background: #3AA1CF;

                &:hover {
                    background: darken(#3AA1CF, 10%);
                }
            }

            &.email {
                background: $primary-color;

                &:hover {
                    background: $primary-color--hover;
                }
            }

            &.saml {
                background: #34a28b;

                &:hover {
                    background: darken(#34a28b, 10%);
                }
            }

            &.btn--full {
                max-width: 350px;
                text-align: left;
                width: 100%;
            }

            &.btn--large {
                width: 300px;
            }
        }

        &.btn-default {
            color: #444444;
        }

        .fa {
            position: relative;

            &.fa-check,
            &.fa-refresh {
                font-size: .9em;
                left: -2px;
                margin-right: .5em;
            }
        }
    }

    .has-error {
        &.no-padding {
            padding: 0;
        }

        .control-label {
            @include border-radius(3px);
            background: #f2f2f2;
            color: #999999;
            font-size: 14px;
            font-weight: normal;
            margin: 1em 0 0;
            padding: .7em 1em;
            width: 100%;

            &:before {
                @extend %font-awesome;
                color: #aaaaaa;
                content: '\f071';
                margin-right: 4px;
            }
        }
    }

    .reset-form {
        @include border-radius(3px);
        font-size: .95em;
        position: relative;

        p {
            color: inherit;
        }
    }

    // Modifier Styles
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &.sub-heading {
            font-weight: 400;
            margin-bottom: 0;
        }

        &.color--light {
            font-weight: 300;
        }
    }

    p {
        .black,
        &.black {
            color: #000000;
        }
    }

    .color--light {
        color: #777777;
    }
}

.select-team__container {
    max-width: 800px;
}

.signup-team-all {
    @include border-radius(2px);
    border: 1px solid #dddddd;
    margin: 0 0 20px;
    border-radius: 4px;
    max-height: calc(75vh - (108px + 89px));

    .signup-team-dir {
        @include legacy-pie-clearfix;
        display: flex;
        align-items: center;
        background: #fafafa;
        border-top: 1px solid #ddd;
        position: relative;
        padding-top: 3px;
        height: 3.8em;
        max-width: 100%;

        &:hover {
            @include single-transition(background-color, .15s, ease);
            background: $white;
        }

        &:first-child {
            border: none;
        }

        .tooltip {
            margin: 10px 0 0 14px;
        }

        .icon {
            cursor: pointer;
            float: left;
            margin: 0 -8px 0 15px;
            opacity: .45;
            height: 20px;

            svg {
                height: 20px;
                width: 20px;
            }
        }

        .fa-lock {
            top: 1px;
            position: relative;
            font-size: 18px;
        }

        a {
            color: inherit;
            display: flex;
            font-size: 1.1em;
            height: 3.5em;
            line-height: 3.6em;
            text-decoration: none;
            padding: 0 20px 0 16px;
            align-items: center;
            flex: 1 1 auto;
            justify-content: space-between;

            &.disabled {
                cursor: default;
                color: $dark-gray;
            }
        }
    }

    .signup-team-dir-err {
        background: #fafafa;
        border-top: 1px solid #d5d5d5;
        color: inherit;
        padding: 5px 15px;

        &:first-child {
            border: none;
        }
    }

    .signup-team-dir__name {
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 50px);

    }

    .signup-team__icon {
        color: $dark-gray;
        font-size: 1.5em;
        position: relative;

        &.fa-spin {
            font-size: .9em;
            right: -2px;
            top: 16px;
        }

        &.fa-info-circle {
            float: left;
            line-height: 1.5em;
            margin-right: .3em;
            padding-left: .5em;
            font-size: 1.5em;
            top: 11px;
        }
    }
}

.authorize-box {
    border: 1px solid $black;
    height: 280px;
    margin: 100px auto;
    width: 500px;
}

.authorize-inner {
    padding: 20px;
}

.authorize-btn {
    margin-right: 6px;
}

.verify_panel {
    margin: 60px auto auto;
    max-width: 380px;
}
