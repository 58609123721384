@charset 'UTF-8';

.mention {
    @include border-radius(3px);
    background: $primary-color;
    color: $white;
    padding-bottom: 2px;
    position: relative;
    z-index: 10;
}

.badge {
    background-color: #777;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    min-width: 10px;
    padding: 3px 7px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

.mentions__fullname {
    opacity: 0.56;
}

.mentions__name {
    @include clearfix;
    cursor: pointer;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    padding: 6px 10px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 101;
    display: flex;

    &.no-flex {
        display: block;
    }

    .Avatar {
        margin-right: 7px;
        margin-top: 3px;
        text-align: center;
        display: block;
    }

    span {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .admin-setting-user--align {
        margin-right: 5px;
    }

    .status {
        width: auto;
        display: block;
        top: 1px;

        svg {
            width: 12px;
            height: 12px;
        }
    }

    // .mentions__name is way too multi-purpose. This rule targets its use in
    // SwitchChannelSuggestion, applying margin in a way that handles null elements correctly.
    .status, .mentions__fullname {
        margin: 0 0 0 8px;
    }

    .fa-user {
        position: relative;
        top: -1px;
    }

    .mention--align {
        @include clearfix;
        text-overflow: ellipsis;
    }
}

.mention__image {
    @include border-radius(20px);
    flex: 0 0 16px;
    display: block;
    font-size: 15px;
    height: 16px;
    line-height: 16px;
    margin-right: 7px;
    margin-top: 3px;
    text-align: center;
    width: 16px;
    min-width: 16px;

    .mention--align {
        display: inline-block;
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.mention__fullname {
    color: rgba(v(center-channel-color-rgb), 0.56);
    margin: 0 0 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mention__shared-user-icon {
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    margin-left: 8px;
    color: rgba(61, 60, 64, 0.56);

    &:before {
        margin: 0;
    }
}

.mention--highlight {
    padding: 0 1px;
    border-radius: 4px;
    background-color: $yellow;

    .mention--highlight {
        padding: 0;
    }
}

.group-mention-link {
    color: var(--link-color);
}

.mention__purpose {
    @include opacity(.5);
    line-height: normal;
    margin-left: 5px;
    white-space: normal;
}

.mention__channelname {
    @include opacity(.5);
}
