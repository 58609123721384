@charset 'UTF-8';

.tip-backdrop {
    background: alpha-color($black, .5);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
}

.tip-overlay {
    @include border-radius(3px);
    background-color: $white;
    max-width: 90%;
    padding: 20px;
    position: absolute;
    width: 350px;
    z-index: 1000;

    .arrow {
        border-color: transparent;
        border-style: solid;
        border-width: 10px;
        display: block;
        height: 0;
        position: absolute;
        width: 0;
    }

    &.tip-overlay--sidebar {
        margin: 50px 0 0 10px;
        max-width: 75%;
        min-height: 330px;

        .tutorial__footer {
            bottom: 20px;
            left: 0;
            padding: 0 20px;
            position: absolute;
            width: 100%;
        }

        .arrow {
            border-left-width: 0;
            border-right-color: $white;
            left: -10px;
            margin-top: -10px;
            top: 80px;
        }
    }

    &.tip-overlay--header--left {
        margin: 10px 0 0 10px;

        .arrow {
            border-left-width: 0;
            border-right-color: $white;
            left: -10px;
            top: 15px;
        }
    }

    &.tip-overlay--header--bottom {
        margin-top: -10px;

        .arrow {
            border-bottom-color: $white;
            border-top-width: 0;
            left: 50%;
            margin-left: -10px;
            top: -10px;
        }
    }

    &.tip-overlay--chat {
        margin-top: -10px;

        .arrow {
            border-bottom-width: 0;
            border-top-color: $white;
            bottom: -10px;
            left: 50%;
            margin-left: -10px;
        }
    }

    h4 {
        font-size: em(16px);
        font-weight: 600;
        margin: 5px 0 15px;
    }

    p {
        font-size: 13px;
        line-height: 1.6;

        strong {
            font-weight: 600;
        }
    }

    .btn {
        @include border-radius(3px);
        background: alpha-color($black, .3);
        border: none;
        color: $white;
        margin: 10px 0;

        &:hover,
        &:active,
        &:focus {
            background: alpha-color($black, .4);
            border: none;
            color: $white;
        }
    }

    .tip-opt {
        font-size: 12px;

        span {
            @include opacity(.9);
        }
    }

    .tutorial__circles {
        margin: 1.5em 0 -1.7em -4px;

        .circle {
            height: 7px;
            margin: 0 4px;
            width: 7px;
        }
    }
}

.tip-button {
    cursor: pointer;
    position: relative;
    right: -10px;
    top: -10px;
    z-index: 998;
}

.tip-div {
    position: absolute;
    right: 0;
    top: 0;

    &.tip-overlay--header {
        right: 2px;
        top: 21px;

        .tip-button {
            @include opacity(.8);
        }
    }

    &.tip-overlay--sidebar {
        @include opacity(.8);
        left: 0;
        top: -9px;
    }
}

.tutorial-steps__container {
    display: flex;
    overflow: auto;
    justify-content: center;
    height: 100vh;
    align-items: center;
    flex-wrap: wrap;

    .tutorial__content {

        .tutorial__steps {
            display: inline-block;
            margin-bottom: 50px;
            max-width: 310px;
            min-height: 480px;
            position: relative;
            text-align: left;
        }
    }

    .tutorial__footer {
        bottom: 0;
        position: absolute;
    }

    h1 {
        font-size: em(40px);
        font-weight: 600;
        margin: -20px 0 30px;
    }

    h3 {
        font-size: em(24px);
        font-weight: 600;
        margin-bottom: 30px;
    }

    .tutorial__circles {
        bottom: 25px;
        position: absolute;
    }

    .tutorial-skip {
        margin-left: 13px;
    }
}

.tutorial__circles {
    margin: 2em 0;

    .circle {
        @include border-radius(9px);
        @include opacity(.2);
        background: $black;
        display: inline-block;
        height: 9px;
        margin: 0 5px;
        width: 9px;

        &.active {
            @include opacity(1);
            background: $primary-color;
        }
    }
}

.tutorial__app-icons {
    margin: 2em 0;
}